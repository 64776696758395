import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import BlockTitle from "../block-title";
import BlogCard from "./blog-card";

import blogImage1 from "../../assets/images/blog/exam2022.jpg";
import blogImage2 from "../../assets/images/blog/sport2022.jpg";
import blogImage3 from "../../assets/images/blog/award2022.jpg";

const BLOG_DATA = [
  {
    image: blogImage1,
    title: "Shopno Foundation Yearly Test 2022",
    date: "04 May, 2022",
    text: "Yearly test for PSC and SSC students was held at Charkultia Dakhil Madrasha. All the schools were invited to participate.",
    link: "#",
    commentCount: "10 Images",
    author: "Charkultia"
  },
  {
    image: blogImage2,
    title: "Shopno Foundation Sports Event 2022",
    date: "06 May, 2022",
    text: "Various types of sports event was held at Charkultia Dakhil Madrasha inviting boys to leave online sports.",
    link: "/news-details",
    commentCount: "10 Images",
    author: "Charkultia"
  },
  {
    image: blogImage3,
    title: "Prize Giving Ceremony 2022",
    date: "06 May, 2022",
    text: "Prize giving cerrmony was held on 06th of May. Our seniors adviors handed over the prizes to the winners.",
    link: "/news-details",
    commentCount: "6 Images",
    author: "Charkultia"
  }
];

const BlogHome = () => {
  const blogCarouselOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      575: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1199: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  };
  return (
    <section className="news-page news-home pt-120 pb-120">
      <Container>
        <Row className="align-items-start align-items-md-center flex-column flex-md-row mb-60">
          <Col lg={7}>
            <BlockTitle
              title={`Latest Events`}
              tagLine="Events"
            />
          </Col>
          <Col lg={5} className="d-flex">
            <div className="my-auto">
              <p className="block-text pr-10 mb-0">
                We hold various types of events throughout the year. Let's have a look.{" "}
              </p>
            </div>
          </Col>
        </Row>
        <Swiper {...blogCarouselOptions}>
          {BLOG_DATA.map(
            (
              { image, title, date, text, link, commentCount, author },
              index
            ) => (
              <SwiperSlide key={index}>
                <BlogCard
                  image={image}
                  title={title}
                  date={date}
                  text={text}
                  link={link}
                  commentCount={commentCount}
                  author={author}
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Container>
    </section>
  );
};

export default BlogHome;
