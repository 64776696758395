import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.998071706536!2d89.48189149999999!3d23.7117629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe5d47ed3912e7%3A0xb150ecbeae52b523!2sShopno%20Foundation!5e0!3m2!1sen!2sbd!4v1652085127876!5m2!1sen!2sbd"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
