import React from "react";
import { Container, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import GalleryCard from "./gallery-card";

import heartImage from "../../assets/images/shapes/heart-2-1.png";


SwiperCore.use([Autoplay]);

const GalleryHome = () => {
  const galleryOptions = {
    spaceBetween: 20,
    slidesPerView: 5,
    autoplay: { delay: 500 },
  };
  const members = ['jaynal.jpg','daoud.jpg','sohel.jpg','akram.jpg','sohidul.jpg','najmul.jpg','nfn_sabuj.jpg','ripon.jpg','tuhin.jpg','nazmul_hasan.jpg','hasan.jpg',
                    'atik.jpg','sweet.jpg','imraj.jpg','sujon.jpg','sabbir.jpg','arif.jpg','sajib.jpg','rony.jpg','turan.jpg']
  return (
    <section className="gallery-home-one">
      
      <Container fluid>
        <Col md={12} lg={7}>
          <div className="block-title">
            <p>
              <img src={heartImage} width="15" alt="" />
              Our Team
            </p>
            <h3>
              Meet those who helps <br /> others in need.
            </h3>
          </div>
        </Col>
        <Swiper {...galleryOptions}>
        {/* <Swiper spaceBetween={20} slidesPerView={5} > */}
          {members.map((val, index) => {
            return(
              <SwiperSlide key={index}>
                <GalleryCard image={`/members/${val}`} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </section>
  );
};

export default GalleryHome;
